import React from 'react';
import PropTypes from 'prop-types';
import { MobileView, isSafari } from 'react-device-detect';
import useClickOutside from '#hooks/useClickOutside';
import styles from './mapRoutesSidebar.module.css';
import CitySelect from '../../forms/citySelect/citySelect';

const logo = require('#assets/images/logo.png');

const MapRoutesSidebar = ({ show, close, routes, onRouteClick, activeRoute, clearRoute }) => {
  const clickRef = useClickOutside(show, close);

  return (
    <div data-testid="MapRoutesSidebar" ref={clickRef} className={`${styles['sidebar']} ${!show && styles['show']} ${isSafari && styles['safari']}`}>
      <MobileView>
        <div className={styles['header']}>
          <img src={logo} alt="Logo" className={styles['header-logo']} />
        </div>
      </MobileView>
      <div className={styles['body']}>
        <CitySelect />
        <ul className={styles['body-list']}>
          {routes.map((route) => (
            <li key={route.group_id} className={`${route.group_id === activeRoute && styles['active']}`}>
              <button type="button" className="btn btn-link" onClick={() => onRouteClick(route.group_id)}>
                {route.name}
              </button>
            </li>
          ))}
        </ul>
        <button type="button" className={`btn btn-link ${styles['clear-button']}`} onClick={clearRoute}>
          No mostrar líneas
        </button>
      </div>
    </div>
  );
};

MapRoutesSidebar.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.func,
  routes: PropTypes.array,
  onRouteClick: PropTypes.func,
  activeRoute: PropTypes.string,
  clearRoute: PropTypes.func,
};

MapRoutesSidebar.defaultProps = {
  show: false,
  close: f => f,
  routes: [],
  onRouteClick: f => f,
  activeRoute: '0',
  clearRoute: f => f,
};

export default MapRoutesSidebar;
