import React from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { decode, encode } from '@mapbox/polyline';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import FormStation from '#components/forms/formStation/formStation';
import { roAPI } from '#utils/axiosAPI';
import styles from './newStation.module.css';
import confirmModal from '#components/modals/confirm/confirm';

import NewStationRouteModal from '#components/modals/newStationRoute/newStationRoute';
import useModal from '#hooks/useModal';

import { WafoForm, WafoFormInput, WafoFormSelect, WafoFormTextArea } from '@wafo/forms';

const NewStation = ({
  history,
  match: { params },
  cities,
  city,
  changeCity,
  routes,
  updateRoutes,
  loading,
  pushAlert,
}) => {

  /** **************************************** */
  /** Selects */
  /** **************************************** */

  const [formCities, setFormCities] = React.useState([]);
  const [formIdCity, setFormIdCity] = React.useState('');
  const [formRoutes, setFormRoutes] = React.useState([]);
  const [formIdRoute, setFormIdRoute] = React.useState('');
  const [formRoute, setFormRoute] = React.useState('');

  const [showNewStationRoute, toggleNewStationRoute] = useModal();

  React.useEffect(() => {
    async function getCities() {
      try {
        loading.set();
        const resp = await roAPI.get('/cities', {
          params: {
            // disabled: false
          },
        });
        setFormCities(resp.rows);
        if (params.id > 0) {
          const [res] = await of(
            roAPI.get(`/stations/${params.id}`, {
              params: {
                nocache: true,
                disabled: '0,1',
              },
            }),
          );
          for (let city of resp.rows) {
            if (city.id_city == res.station_routes[0].id_city) {
              setStation({ ...res, id_city: city.id_city, latlng: `${res.latitude},${res.longitude}`, });
              setFormRoute(res.station_routes[0]);
              setFormIdCity(city.id_city);
              break;
            }
          }
        }
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    getCities();
  }, [loading]);

  React.useEffect(() => {
    async function fetchRoutes() {
      loading.set();
      const [res] = await of(
        roAPI.get(`/routes/city/${formIdCity}`, {
          params: {
            nocache: true,
            disabled: '0,1',
          },
        }),
      );
      setFormRoutes(res);
      loading.stop();
    }
    if (formIdCity > 0) fetchRoutes();
  }, [formIdCity]);

  const setCity = ({ target: { value } }) => {
    setFormIdCity(value);
  };

  const setRoute = ({ target: { value } }) => {
    setFormIdRoute(value);
    for (let r of formRoutes) {
      if (r.id_route == value) {
        setFormRoute(r)
        break;
      }
    }
  };

  const handleModalClose = () => {
    toggleNewStationRoute();
  };

  /** **************************************** */
  /** Station */
  /** **************************************** */
  const [station, setStation] = React.useState({});
  const [valid, setValid] = React.useState({
    info: true,
  });

  const submitStation = async (form, values) => {
    if (!form.valid) {
      //checkValidity(form);
      pushAlert({
        type: 'warning',
        title: 'Alerta',
        message:
          'Hay un problema con uno o más de los campos de la forma. Por favor siga las instrucciones en pantalla.',
        timer: 3000,
      });
    } else {
      try {
        loading.set();
        if (params.id) {
          const updatedStation = {
            code: values.code,
            name: values.name,
            disabled: !!values.disabled,
            latitude: Number(station.latlng.split(',')[0]),
            longitude: Number(station.latlng.split(',')[1]),
            address: station.address,
            station_routes: station.station_routes
          };
          await roAPI.put(`/stations/${params.id}`, updatedStation);
        } else {
          const newStation = {
            code: values.code,
            name: values.name,
            id_route: Number(values.id_route),
            //disabled: !!values.disabled,
            latitude: Number(station.latlng.split(',')[0]),
            longitude: Number(station.latlng.split(',')[1]),
            address: station.address
          };
          await roAPI.post('/stations/', newStation);
        }
        history.push('/panel/estaciones');
        loading.stop();
      } catch (err) {
        console.error(err);
        loading.stop();
      }
    }
  };

  const handleMarkerAccept = React.useCallback(result => {
    if (result.addresses.length) {
      setStation(prevStation => ({
        ...prevStation,
        address: result.addresses[0].address.freeformAddress,
        latlng: `${result.position[0].toFixed(6)},${result.position[1].toFixed(6)}`,
      }));
    } else {
      setStation(prevStation => ({
        ...prevStation,
        latlng: `${result.position[0].toFixed(6)},${result.position[1].toFixed(6)}`,
      }));
    }
  }, []);

  const checkValidity = form => {
    setValid(() => {
      const newValidity = {
        info: true,
        map: true,
      };
      if (!form.id_route.valid || !form.code.valid || !form.name.valid || !form.latlng.valid) {
        newValidity.info = false;
      }
      return newValidity;
    });
  };

  const deleteRoute = (idRoute) => {
    setStation({ ...station, station_routes: station.station_routes.filter(route => route.id_route !== idRoute) });
  };

  const setNewRoute = (idRoute) => {
    const routeToAdd = formRoutes.find(route => route.id_route === idRoute);
    if (routeToAdd && !station.station_routes.find(route => route.id_route === idRoute)) {
      setStation({ ...station, station_routes: [...station.station_routes, routeToAdd] });
      toggleNewStationRoute();
    }
  };

  /** **************************************** */
  /** Render */
  /** **************************************** */

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <button type="button" className={`btn btn-link ${styles['back']}`} onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '.5rem' }} />
            <span>Regresar</span>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <FormStation
            onSubmit={submitStation}
            initialValues={station}
            cities={formCities}
            city={formIdCity}
            routes={formRoutes}
            route={formRoute}
            setRoute={setRoute}
            deleteRoute={deleteRoute}
            handleModal={toggleNewStationRoute}
            station={station}
            onClick={handleMarkerAccept}
            onCityChange={setCity}
            loading={loading}
            id={params.id}
          />
          <button type="submit" form="formStation" className="btn btn-primary" style={{ /*position: 'absolute', bottom: '20px'*/ }}>
            Guardar cambios
          </button>
        </div>
      </div>
      <NewStationRouteModal showModal={showNewStationRoute} toggleModal={handleModalClose} routes={formRoutes} setRoute={setNewRoute} />
    </React.Fragment>
  );
};

NewStation.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.any,
  }),
  location: PropTypes.shape({
    state: PropTypes.any,
  }),
  cities: PropTypes.array,
  city: PropTypes.string,
  changeCity: PropTypes.func,
  routes: PropTypes.array,
  updateRoutes: PropTypes.func,
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
  pushAlert: PropTypes.func,
};

NewStation.defaultProps = {
  history: {
    goBack: f => f,
    push: f => f,
  },
  match: {
    params: {},
  },
  location: {
    state: {},
  },
  cities: [],
  city: '',
  changeCity: f => f,
  routes: [],
  updateRoutes: f => f,
  loading: {
    set: f => f,
    stop: f => f,
  },
  pushAlert: f => f,
};

export default reduxConnect(
  state => ({
    cities: state.cities,
    city: state.city,
    routes: state.routes,
  }),
  dispatch => ({
    updateRoutes: (routes, filter) => dispatch(actions.updateRoutes(routes, filter)),
    changeCity: city => dispatch(actions.changeCity(city)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
    pushAlert: alert => dispatch(actions.alert(alert)),
  }),
)(NewStation);
