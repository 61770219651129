import React from 'react';
import PropTypes from 'prop-types';
import ModalBase from '../modalBase/modalBase';

import { WafoFormSelect } from '@wafo/forms';

const NewStationRouteModal = ({ showModal, toggleModal, routes, setRoute }) => {

  const [selectedRoute, setSelectedRoute] = React.useState('');

  const handleChange = (event) => {
    setSelectedRoute(event.target.value);
  };

  return (
    <div>
      <ModalBase
        show={showModal}
        closeModal={toggleModal}
        headerStyle={{ paddingTop: '0' }}
        cardStyle={{ width: '30%' }}
      >
        <div className="row">
          <WafoFormSelect
            name="id_route"
            customClass="col-12"
            label="Línea"
            defaultValue="Selecciona la línea"
            value={selectedRoute}
            options={routes.map(x => ({
              value: x.id_route,
              display: x.name,
            }))}
            onChangeCallback={handleChange}
          />
          <div className="col-12">
            <button type="button" className="btn btn-primary btn-block" onClick={() => setRoute(selectedRoute)}>
              <span>Asignar línea</span>
            </button>
          </div>
        </div>
      </ModalBase>
    </div>
  );
};

NewStationRouteModal.propTypes = {
  showModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  poi: PropTypes.any,
};

NewStationRouteModal.defaultProps = {
  showModal: false,
  toggleModal: f => f,
  poi: {},
};

export default NewStationRouteModal;
